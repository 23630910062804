body {
  margin: 0px;
  padding: 0px;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
:root{
  margin: 0px;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* NepaliCalendar.css */
.modal {
  display: none; /* Hidden by default */
}

.modal.show {
  display: flex;
}

.modal-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal-container {
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.modal-close {
  cursor: pointer;
}
ul, li {
  /* Tailwind utility to override */
  list-style: disc; /* Or any desired style */
  margin: 0;
  padding: 0;
  text-align: start;
}
@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.rotate-y-infinite {
  animation: rotateY 5s linear infinite;
}