.add-template-div{
    width: 150px;
    height: 100px;
    border: 2px;
    border-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: antiquewhite;
    border-radius: 5px;
    transition: all ease-in-out 0.1s;
}
.add-template-div:hover{
    transform: scale(1.05);
}